import Seo from 'src/components/seo/Seo'

import SustentabilidadeBrasilComponent from '../../components/Institucional/SustentabilidadeBrasilComponent'

function SustentabilidadeBrasil() {
  return (
    <>
      <Seo language="pt-br" title="Institucional | Decathlon" />
      <SustentabilidadeBrasilComponent />
    </>
  )
}

export default SustentabilidadeBrasil
